<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M5.48 2a1.02 1.02 0 0 0-1.52-.1L2.4 3.45c-.73.73-1 1.75-.68 2.65a26.35 26.35 0 0 0 6.25 9.92c2.8 2.8 6.19 4.94 9.92 6.25.9.32 1.93.05 2.65-.68l1.55-1.55a1.02 1.02 0 0 0-.1-1.52l-3.45-2.69a1.02 1.02 0 0 0-.87-.18l-3.29.82a2.62 2.62 0 0 1-2.48-.7L8.22 12.1a2.62 2.62 0 0 1-.69-2.48l.83-3.29a1.02 1.02 0 0 0-.19-.87L5.48 2ZM2.83.76A2.62 2.62 0 0 1 6.74 1l2.7 3.46c.49.63.66 1.46.47 2.24L9.09 10a1.02 1.02 0 0 0 .26.96l3.69 3.69a1.02 1.02 0 0 0 .97.26l3.28-.82a2.62 2.62 0 0 1 2.24.47l3.46 2.7a2.6 2.6 0 0 1 .24 3.91l-1.55 1.55a4.17 4.17 0 0 1-4.31 1.06 27.95 27.95 0 0 1-10.52-6.63A27.95 27.95 0 0 1 .22 6.63a4.17 4.17 0 0 1 1.06-4.31L2.83.77Z"
    />
  </svg>
</template>
